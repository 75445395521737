<template>
  <section class="dtc-main-section mt-2 mr-2">
    <!-- <quill-editor
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    >
    </quill-editor> -->
    <section class="search-block">
      <h5 class="big-title">
        大手故事集管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="主旨">
          <b-input v-model="searchSubject" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-input-group prepend="備註">
          <b-input v-model="searchRemark" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="warning" @click="openModal(1)">新增故事集</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding: 2.5px 0 0 30px;-webkit-box-orient: horizontal;">
        <b-button
          variant="success"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >編輯</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="deleteModal(item)"
          class="mr-2"
          >刪除</b-button
        >
      </div>

      <div :title="item.Subject">
        {{ item.Subject || "" }}
      </div>
      <div>
        {{ item.StartDate ? $twDate(item.StartDate) : "" }}
      </div>
      <div>
        {{ item.EndDate ? $twDate(item.EndDate) : "" }}
      </div>
      <div>
        {{ item.Creater ? item.Creater : "" }}
      </div>
      <div>
        {{ item.CreateTime ? $twDate(item.CreateTime) : "" }}
      </div>

      <div>
        {{ item.Update ? item.Update : "" }}
      </div>
      <div>
        {{ item.UpdateTime ? $twDate(item.UpdateTime) : "" }}
      </div>
      <div :title="item.Type">
        {{ item.Type || "" }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark || "" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}故事集資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="主旨" style="grid-column: 1/-1;">
            <b-input
              style="padding-right:28px"
              v-model="modalItem.Subject"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="備註" style="grid-column: 1/-1;" class="mt-2">
            <b-input v-model="modalItem.Remark"></b-input>
          </b-input-group>
          <b-input-group prepend="類型" class="mt-2">
            <b-select
              :options="['story', 'meeting']"
              v-model="modalItem.Type"
            ></b-select>
          </b-input-group>
          <div></div>
          <b-input-group prepend="上線時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.StartDate"
              @update="(t) => (modalItem.StartDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>

          <b-input-group prepend="下線時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.EndDate"
              @update="(t) => (modalItem.EndDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>
          <div class="mt-2" style="grid-column: 1/-1">
            <quill-editor
              v-model="modalItem.Content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            >
            </quill-editor>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "主旨", key: "Subject", sortDesc: null },
  { name: "上線時間", key: "StartDate", sortDesc: null },
  { name: "下線時間", key: "EndDate", sortDesc: null },
  { name: "建立人", key: "Creater", sortDesc: null },
  { name: "建立時間", key: "CreateTime", sortDesc: null },
  { name: "修改人", key: "Update", sortDesc: null },
  { name: "修改時間", key: "UpdateTime", sortDesc: null },
  { name: "類型", key: "Type", sortDesc: null },
  { name: "備註", key: "Remark", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        Type: "",
        Subject: "",
        Content: "",
        Remark: "",
        StartDate: "",
        EndDate: "",
        Creater: "",
        CreateTime: "",
        Update: "",
        UpdateTime: "",
      },
      modalType: 1,

      //search
      searchSubject: "",
      searchRemark: "",

      //searchOptions or ModalOptions
      schoolNameOptions: [],
      content: "<h2>I am Example</h2>",
      editorOption: {
        // some quill options
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {},

  methods: {
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      // console.log("editor change!", quill, html, text);
      // this.content = html;
    },
    clearSearch() {
      this.searchSubject = "";
      this.searchRemark = "";
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          Type: "",
          Subject: "",
          Content: "",
          Remark: "",
          StartDate: "",
          EndDate: "",
          Creater: Boolean(sessionStorage.fullname)
            ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
            : "",
          CreateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          Update: Boolean(sessionStorage.fullname)
            ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
            : "",
          UpdateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
        };
      } else {
        this.modalItem = JSON.parse(JSON.stringify(item));
        this.modalItem.StartDate = this.$twDate(item.StartDate);
        this.modalItem.EndDate = this.$twDate(item.EndDate);
        this.modalItem.Update = Boolean(sessionStorage.fullname)
          ? `${window.sessionStorage.fullname}`.replace(/\"/g, "")
          : "";
        this.modalItem.UpdateTime = this.$moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/StudentStory`;
      const obj = {
        ...this.modalItem,
      };
      obj.StartDate = this.$usDate(obj.StartDate);
      obj.EndDate = this.$usDate(obj.EndDate);
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `招生故事集${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteModal(item) {
      const url = `api/StudentStory/${item.Id}`;

      try {
        await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      let bQs = false;

      if (this.searchSubject) {
        qs = this.$equalSubstringofFilter("Subject", qs, this.searchSubject);
        bQs = true;
      }
      if (this.searchRemark) {
        if (bQs) {
          qs = this.$appendSubstringofFilter("Remark", qs, this.searchRemark);
        } else {
          qs = this.$equalSubstringofFilter("Remark", qs, this.searchRemark);
        }
        bQs = true;
      }

      try {
        let { Items, Count } = await window.axios.get(`api/StudentStory${qs}`);

        this.items = [...Items];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 160px 400px 130px 130px 130px 160px 160px 130px 130px 300px;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 38px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
::v-deep .ql-container {
  height: 460px;
}
</style>
